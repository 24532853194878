import React, { useEffect, useState, useCallback } from "react"
import * as styles from "./list.module.css"
import Pagenation from "components/tools/pagination.js"
import Layout from "components/common/layout.js"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { getInterestListAsync } from "store/modules/interestReducer.js"
import Story from "components/story/story"
import { topics } from "utils/data.js"
import OrderMenu from "components/tools/orderMenu"
import { Fragment } from "react/cjs/react.production.min"
import queryString from "query-string"
import { navigate } from "gatsby"
const List = props => {
  const dispatch = useDispatch()
  const getInterestList = useCallback(
    (currentPage, pagePostSize, order, topicNum) =>
      dispatch(
        getInterestListAsync(currentPage, pagePostSize, order, topicNum)
      ),
    [dispatch]
  )

  const { page } = queryString.parse(props.location.search)

  const { interestList, total } = useSelector(
    ({ interestReducer }) => interestReducer,
    shallowEqual
  )
  const [order, setOrder] = useState(1)

  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1)
  const pagePostSize = 16
  const [topicNum, setTopicNum] = useState(0)

  useEffect(() => {
    console.log(topicNum)
    ;(async () => {
      await getInterestList(currentPage, pagePostSize, order, topicNum)
    })()
  }, [order, currentPage, topicNum])

  const buttonClick_ = event => {
    console.log(event.target.id)
    setTopicNum(Number(event.target.id))
    setCurrentPage(1)
  }

  return (
    <Layout>
      <div>
        <div className={"title"}>
          <p className={`subtitle`} style={{ marginTop: "59px" }}>
            {topics.get(topicNum)?.contents ?? "전체보기"}
          </p>
          <p className={styles.titleIntro}>{titleIntroText[topicNum]}</p>
          <div>
            (전체
            <p className={`red subnotice`}>{total}</p>개 스토리)
          </div>
        </div>

        <div className={styles.menuBox} id="menuBox">
          <input
            style={{ display: "none" }}
            className={styles.radioInput}
            type="radio"
            value="0"
            name="radioButton"
            onClick={buttonClick_}
            id="0"
          ></input>
          <label
            className={styles.radioLabel}
            htmlFor="0"
            style={
              topics.get(topicNum)?.contents
                ? {}
                : { backgroundColor: "#e95c63", color: "#fff" }
            }
          >
            전체보기
          </label>
          {Array.from(topics).map(([topic_no, contents]) => (
            <Fragment key={topic_no}>
              {topic_no < 21 ? (
                <>
                  {" "}
                  <input
                    style={{ display: "none" }}
                    className={styles.radioInput}
                    type="radio"
                    value="0"
                    name="radioButton"
                    onClick={buttonClick_}
                    id={topic_no}
                  ></input>
                  <label
                    className={styles.radioLabel}
                    htmlFor={topic_no}
                    style={{ lineHeight: "23px" }}
                  >
                    {contents["contents"]}
                  </label>
                </>
              ) : (
                <> </>
              )}
            </Fragment>
          ))}
          <div>
            {Array.from(topics).map(([topic_no, contents]) => (
              <Fragment key={topic_no}>
                {topic_no >= 21 ? (
                  <>
                    <input
                      style={{ display: "none" }}
                      className={styles.radioInput}
                      type="radio"
                      value="0"
                      name="radioButton"
                      onClick={buttonClick_}
                      id={topic_no}
                    ></input>
                    <label
                      className={styles.radioLabel}
                      htmlFor={topic_no}
                      style={{ lineHeight: "16px" }}
                    >
                      {contents["contents"]}
                    </label>
                  </>
                ) : (
                  <> </>
                )}
              </Fragment>
            ))}
          </div>
        </div>
        <OrderMenu
          order={order}
          setOrder={setOrder}
          style={{ justifyContent: "flex-start" }}
          setCurrentPage={setCurrentPage}
        />

        <div className={`colContainer ${styles.main}`}>
          {interestList.map(post => (
            <Story
              registerTime={post.publishTime}
              key={post.story_no}
              story_no={post.story_no}
              title={post.title}
              introText={post.introText}
              thumb_title_uuid={post.thumb_title_uuid}
              cateMain={post.cateMain}
              cateSub={post.cateSub}
              Like={post.Like}
              free={post.free}
            />
          ))}
        </div>
        <div style={{ paddingTop: "50px" }}>
          <Pagenation
            total={total}
            pagePostSize={pagePostSize}
            pageSize={10}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            Nav={true}
          />
        </div>
      </div>
    </Layout>
  )
}

export default List
const titleIntroText = {
  0: "",
  1: "음식을 좋아한다면",
  2: "동물을 좋아한다면",
  3: "어려운 말 때문에 고민이라면",
  4: "사람들의 마음과 기분이 궁금하다면",
  5: "다른 사람과 좋은 관계를 맺고 싶다면 ",
  6: "꼭 지켜야 하는 예절을 알고 싶다면",
  7: "놀라운 발명과 과학 지식을 알고 싶다면",
  8: "일상에서 필요한 숫자 개념이 궁금하다면",
  9: "옛날에 무슨 일이 있었는지 궁금하다면 ",
  10: "지구와 환경 문제에 관심이 있다면",
  11: "나의 기본적인 권리를 알고 싶다면 ",
  12: "젠더 이슈를 쉽게 알아보고 싶다면",
  13: "요즘 무엇이 유행하는지 궁금하다면 ",
  14: "내 건강을 지키는 방법을 알고 싶다면 ",
  15: "요리, 게임, 예술 등 여가생활을 즐기고 싶다면",
  16: "자기 일을 스스로 결정해보고 싶다면",
  17: "깔끔하고 멋진 사람이 되고 싶다면 ",
  18: "다양한 직업과 직장 생활에 대해 알고 싶다면",
  19: "대중교통, 물건 구입 등 지역사회에서 살아가는 방법이 궁금하다면",
  20: "토론 수업을 하고 싶다면 ",
  21: "문제 해결 과정을 배우고 싶다면 ",
  22: "직접 가볼 수 있는 장소를 알고 싶다면 ",
  23: "이해하고 추론하는 힘을 기르고 싶다면",
  24: "디지털 시대에 필요한 - 미디어 사용법이 궁금하다면 ",
  25: "그림으로 이야기를 이해하고 싶다면 ",
}
